import "./entries/homepage/homepage.scss";
import "./entries/homepage/homepage-news-index.scss";

import "isomorphic-unfetch";
import $ from "jquery";
import modules from "ym";
import "./modules/swiper";

/**
 * переключалка рейтингов
 */
(function () {
  let loaded = false;

  const rating_blocks = $(".rating-blocks");
  const rating_sort_type = $(".rating-blocks__sort-type");
  const rating_sort_who = $(".rating-blocks__sort-who");

  function reload() {
    if (!loaded) {
      loaded = true;

      $(".rating-blocks").addClass("rating-blocks--load");

      $.ajax({
        url: location.href,
        data: { ajax: 1 },
        success: (data) => {
          let html = $("<div />")
            .append($.parseHTML(data))
            .find(".rating-blocks__body");

          $(".rating-blocks__body").replaceWith(html);
          $(".rating-blocks").removeClass("rating-blocks--load");
        },
      });
    }
  }

  rating_sort_type.on("click", "li", function () {
    rating_blocks.attr("data-selected-type", $(this).attr("data-type"));
    reload();
  });

  rating_sort_who.on("click", "li", function () {
    rating_blocks.attr("data-selected-who", $(this).attr("data-who"));
    reload();
  });
})();

if (document.querySelector(".homepage-swiper")) {
  const is_ie11_or_not_ie = !window.ActiveXObject;
  const is_enable_swiper = is_ie11_or_not_ie || true;

  is_enable_swiper || $(".homepage-swiper").remove();
  is_enable_swiper ||
    $(".page-post-header--swapper").removeClass("page-post-header--swapper");
  is_enable_swiper &&
    modules.require(["swiper"], (Swiper) => {
      const viewed = {};

      new Swiper(".homepage-swiper", {
        loop: true,
        // parallax: true,

        // initialSlide: 2,
        spaceBetween: 10,
        // slidesPerView: 2,
        // centeredSlides: true,
        // slideToClickedSlide: true,
        grabCursor: true,

        // fade, flip, cube, coverflow
        effect: "fade",

        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // type: 'progressbar',
          // type: 'fraction',
        },
        scrollbar: {
          el: ".swiper-scrollbar",
        },

        // Enable lazy loading
        preloadImages: false,
        watchSlidesVisibility: true,
        lazy: { loadOnTransitionStart: true },

        on: {
          slideChange: function () {
            const slide = this.slides[this.activeIndex];
            const home_swiper_id =
              parseInt(slide.getAttribute("data-home-swiper-id")) || 0;
            if (home_swiper_id && !viewed[home_swiper_id]) {
              viewed[home_swiper_id] = true;

              fetch("/home-swiper/" + home_swiper_id + "/add-view/", {
                credentials: "include",
                method: "POST",
              });
            }
          },
        },
      });
    });
}
